<template>
  <div class="no-access">
    <h1>Sorry. No Access.</h1>
    <p>You're logged in with an account that doesn't have access to the requested page.</p>
    <router-link to='/'>
      <es-btn>
        Go to the CMS home page
        <fa icon="chevron-right" />
      </es-btn>
    </router-link>
  </div>
</template>
<script>
export default {
  name: 'NoAccess',
}
</script>
<style lang="scss" scoped>
.no-access {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  & h1 {
    color: $dark-indigo;
  }
  & a {
    text-decoration: none;
    color: $dark-indigo;
    font-family: $headline;
  }
}
</style>